<template>
  <a-modal
    title="人员分配"
    :maskClosable="false"
    v-model="visible"
    :confirmLoading="confirmLoading"
    @ok="editHandleOk"
    @cancel="editHandleCancel"
    width="800px"
    :destroyOnClose="true"
    class="edit-permission-component"
  >
    <div class="content">
      <p class="title">人员列表</p>
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="10" :sm="24">
              <a-form-item label="姓名">
                <a-input v-model="queryParam.keyword" placeholder="请输入人员名称"/>
              </a-form-item>
            </a-col>
            <a-col :md="2" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" icon="search" @click="queryClick">查询</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <a-table
        class="table"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :columns="columns"
        :dataSource="data"
        rowKey="id"
        :loading="loading"
        :pagination="pagination"
        @change="pageChange">
      </a-table>
    </div>
  </a-modal>
</template>

<script>
import * as userapi from '@/api/manage/user'
import { findOrganizationUser } from '@/api/findAll'
import { teamAllocation } from '@/api/manage/organization'
export default {
  name: 'DistributionComponent',
  data () {
    return {
      visible: false,
      confirmLoading: false,
      organizationId: '',
      loading: false,
      pagination: {
        current: 1,
        total: 0,
        showTotal: function (total, range) {
          return '数据总数：' + total + ''
        },
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: [ '10', '30', '50' ]
      },
      queryParam: {
        page: 0,
        size: 10,
        keyword: null,
        organizationId: null,
        enabled: 2
      },
      columns: [
        {
          title: '工号',
          dataIndex: 'jobNumber'
        },
        {
          title: '用户姓名',
          dataIndex: 'realName'
        },
        {
          title: '联系电话',
          dataIndex: 'mobile'
        }
      ],
      data: [],
      selectedRowKeys: []
    }
  },
  watch: {
    visible (n) {
      if (n === true) {
        this.queryParam = this.$options.data().queryParam
        this.pagination = this.$options.data().pagination
        this.query()
        this.defaultCheck(this.organizationId)
      }
    }
  },
  methods: {
    query () {
      this.loading = true
      userapi.findUserList(this.queryParam).then(res => {
        if (res.status !== 200) {
          throw new Error(res.message)
        }
        this.data = res.body.data
        this.pagination.total = res.body.total
      }).catch((e) => {
        this.$notification.error({
          message: '提示',
          description: e.message
        })
      }).finally(() => {
        this.loading = false
      })
    },
    queryClick () {
      this.pagination.current = 1
      this.queryParam.page = 0
      this.query()
    },
    pageChange (pageNumber) {
      this.pagination.current = pageNumber.current
      this.queryParam.page = pageNumber.current - 1
      this.queryParam.size = pageNumber.pageSize
      this.query()
    },
    defaultCheck (id) {
      findOrganizationUser({ id }).then(res => {
        if (res.status !== 200) {
          throw new Error(res.message)
        }
        const ids = res.body.map((n) => {
          return n.id
        })
        this.selectedRowKeys = ids
      }).catch((e) => {
        this.$notification.error({
          message: '提示',
          description: e.message
        })
      }).finally(() => {
        this.loading = false
      })
    },
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    editHandleOk () {
      console.log({ id: this.organizationId, userIds: this.selectedRowKeys })
      teamAllocation({ id: this.organizationId, userIds: this.selectedRowKeys }).then(res => {
        if (res.status !== 200) {
          throw new Error(res.message)
        }
        this.$notification.success({
          message: '提示',
          description: '分配成功'
        })
        this.visible = false
      }).catch((e) => {
        this.$notification.error({
          message: '提示',
          description: e.message
        })
      })
    },
    editHandleCancel () {
      this.visible = false
    }
  }
}
</script>

<style scoped>
  .content{
    height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .title{
    text-align: center;
    font-size: 30px;
  }
</style>
