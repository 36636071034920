import { axios } from '@/utils/request'

const basic = '/manage/organization'
const api = {
  // 添加小组
  addOrganization: basic + '/addOrganization',
  // 分页查询小组
  findOrganization: basic + '/findOrganization',
  // 分页查询所有小组
  findAllOrganization: basic + '/findAllOrganization',
  // 删除小组
  delOrganization: basic + '/delOrganization',
  // 小组人员分配
  teamAllocation: basic + '/teamAllocation'

}
export function addOrganization (parameter) {
  return axios({
    url: api.addOrganization,
    method: 'post',
    params: parameter
  })
}
export function findOrganization (parameter) {
  return axios({
    url: api.findOrganization,
    method: 'post',
    params: parameter
  })
}
export function findAllOrganization (parameter) {
  return axios({
    url: api.findAllOrganization,
    method: 'post',
    params: parameter
  })
}
export function delOrganization (parameter) {
  return axios({
    url: api.delOrganization,
    method: 'post',
    params: parameter
  })
}
export function teamAllocation (parameter) {
  return axios({
    url: api.teamAllocation,
    method: 'post',
    data: parameter
  })
}
